.forgotDiv {
  background: #132a58;
  width: 40%;
  padding: 20px;
  margin: auto;
  border-radius: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}

@media (max-width: 768px) {
  .forgotDiv {
    width: 80%;
  }
}
