.npc2FormCreation {
  display: "flex";
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #04101e;
  color: white;
}
.logoImg {
  height: 110px;
  width: 110px;
}

.npc2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.npc2 div {
  display: flex;
  align-items: center;
}
.npc2 svg {
  font-size: 2rem;
  margin-right: 10px;
  cursor: pointer;
}
.npc2FormContainer {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 20px;
}
.npc2Form {
  display: flex;
  flex-direction: column;
  width: 80%;
  /* max-height: 60vh; */
  overflow: auto;
  align-items: center;
  margin: auto;
  gap: 10px;
}
/* Styling the scrollbar */
.npc2Form::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.npc2Form::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.npc2Form::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
}

.npc2Form::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}
.npc2Form > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.npc2FormInputContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
