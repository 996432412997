.changePasswordDiv {
  width: 40%;
  background: #132a58;
  padding: 20px;
  border-radius: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

@media (max-width: 640px) {
  .changePasswordDiv {
    width: 80%;
    padding: 10px;
  }
}
