.projectDetailsPage {
  min-height: 100vh;
  background: #04101e;
}

/* .projectDetails {
  background: #04101e;
  min-height: 100vh - 89px;
} */
.cardChangeLog {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 5px 20px 5px 10px;
}
.iconHome svg {
  font-size: 1.8rem;
  color: white;
  cursor: pointer;
}
.createdProject {
  /* display: flex; */
  /* flex-direction: column; */
  background: #1e1f26;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: auto;
  padding: 20px;
  border-top: 1px solid white;
  border-bottom: 2px solid white;
}
.createdProject > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
}
.createdProject > div > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.createdProject label {
  width: 150px;
}

.statusSelection {
  /* width: 20%; */
  cursor: pointer;
  width: 60%;
  border-bottom: 1px solid white;
  background: #132a58;
  color: white !important;
}
.statusSelection option {
  color: white;
}
.createdProject input {
  background: none;
  width: 60%;
  /* border: none; */
}

/*  */
.numItem {
  color: white;
  text-align: center;
  font-size: 1.4rem;
}
.calcProject {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: white;
  padding: 10px;
}

.calcProject > div {
  padding: 10px;
}
.calcProjectHeader {
  background: #132a58;
  color: white;
  font-size: 1.4rem;
  border-left: 1px solid white;
  padding-left: 20px;
}

.cardValuation {
  width: 55%;
}

.changeCardContainer {
  width: 40%;
  min-height: 150px;
  max-height: 250px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: #1e1f26;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
}

.headerRow {
  width: 100%;
  justify-content: space-between;
  overflow: auto !important;
  background: "#000",
}

.search-filter {
  flex-shrink: 0;
  background: "#000";
  padding-left: 10px
}
.buttons {
  flex-shrink: 0;
  display: flex;
}
/* @media (max-width: 1300px) {
} */
@media (max-width: 1006px) {
  .createdProject {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .createdProject {
    grid-template-columns: 1fr;
  }
  .calcProject {
    grid-template-columns: repeat(2, 1fr);
  }
  .saveBtn {
    margin-right: 0 !important;
  }

  .titleText {
    font-size: 1rem !important;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 10px !important;
  }
}

@media (max-width: 640px) {
  .cardChangeLog {
    flex-direction: column;
    /* align-items: center; */
  }
  .cardValuation {
    width: 90%;
  }
  .changeCardContainer {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .calcProject {
    grid-template-columns: 1fr;
  }

  .headerRow {
    padding: 10px 2px !important;
  }
}
