/* CheckoutForm.css */

.checkout-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 2px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: #000;
  }
  
  .card-element,
  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .submit-button {
    width: 100%;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: #ff0000;
    margin-top: 10px;
  }

  .image-above-card {
    width: 220px; /* Adjust as needed */
    height: 220px;
    display: block;
    border-radius: 20px;
    margin: 0 auto 10px; /* Center horizontally, adjust as needed */
  }
  
  .image-below-button {
    width: 40%; /* Adjust as needed */
    margin: 10px auto; /* Center horizontally, adjust as needed */
    margin-bottom: 0;
    display: block; /* Ensure it's displayed as a block-level element */
  }
  
  .custom-input {
    border: 1px solid #2a84ea;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #f1f1f1;
    color: #000000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    line-height: 21px;
    outline: none;
  }
  