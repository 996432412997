.passwordResetDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  background: #132a58;
  padding: 20px;
  color: white;
  border-radius: 15px;
}

@media (max-width: 640px) {
  .passwordResetDiv {
    width: 80%;
  }
}
